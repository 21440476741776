import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { ListOrder } from "../../api/index";
import Moment from 'react-moment';
import 'moment/locale/id';
import NumberFormat from 'react-number-format';
// import { Button } from 'reactstrap';


class PurchaseList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetching: true,
            listOrder: null,
            data: null
        }
    }

    componentDidMount() {
        ListOrder().then(res => {
            console.log(res)
            this.setState({ data: res })
        })
    }

    onClickOrderHandler = (event,id) => {
        const transId = id;
        this.props.history.push('/order/detail/'+transId);
        
    }


    render() {

        let listOrder = null;        
        if (this.state.data !== null) {
            listOrder = this.state.data.map((value, index) => (
                // console.log(value.Bank)
                <div className="purchase-list-wrapper" onClick={(event)=>{this.onClickOrderHandler(event,value.transactionId)}}>
                    <div className="icon-list">
                        <i className="fas fa-shopping-bag"></i>
                    </div>
                    <div className="purchase-description">
                        <div className="no-pesanan">Invoice: {value.invoiceNumber}</div>
                        <div className="number-detail">
                            <div className="total">Total : <strong><NumberFormat value={value.subtotal} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                            </strong></div>
                            <div className="date">Tanggal Pembelian : <strong>
                                <Moment locale="id" format="D MMM YYYY">{value.createdAt}</Moment>
                            </strong></div>
                        </div>
                        <div className="deadline">
                            Bayar sebelum <Moment locale="id" format="LLLL">{value.expireDate}</Moment>
                </div>
                        <div className="payment-method">
                            <table>
                                <tr>
                                    <td>Metode Pembayaran</td>
                                    <td>:</td>
                                    <td>{value.Bank.bankname}</td>
                                </tr>
                                <tr>
                                    <td>Nomor Rekening</td>
                                    <td>:</td>
                                    <td>{value.Bank.norek}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className="purchase-action">
                        <div className="label-status">Status</div>
                        <div className="status">
                            {value.StatusOrder.statusName}
                </div>
                    </div>
                </div>
            ))
        }

        return (
            <div className="purchase-list-wrapper">
                {/* <div className="purchase-list-header flex-row">
                    <div className="filter-label">Filter:</div>
                    <div className="filter-button-wrapper flex-row">
                        <Button>Menunggu Konfirmasi</Button>
                        <Button>Menunggu Konfirmasi</Button>
                        <Button>Menunggu Konfirmasi</Button>
                    </div>
                </div> */}
                <div className="purchase-list-body">

                    {listOrder}

                </div>
            </div>
        )
    }
}

export default withRouter(PurchaseList);