import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import NumberFormat from 'react-number-format';


class ProductCard extends Component {

    render() {
        
        let display_pict =
            <div className="product-card-display loading-background"></div>

        if (this.props.product.Pictures) {
            display_pict =
                <div className="product-card-display contain-pict zoom-ipbpress" style={{
                    background: `url(${this.props.product.Pictures[0].url_small})`,
                }}>
                </div>
        }

        let price = null;
        if (this.props.product.base_price !== this.props.product.promoPrice || this.props.product.promoPercentage > 0) {
            price =
                <div>
                    <span className="product-price__original">
                        <NumberFormat value={this.props.product.base_price} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                    </span>
                    <span className="product-price__reduced">
                        <NumberFormat value={this.props.product.promoPrice} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                    </span>
                </div>
        } else if (this.props.product.promoPrice == 1) {
            price = <span>

        </span> 
        }
        else if (this.props.product.status_books == 1) {
            price = <span>
            {this.props.product.edisi_jurnal}
        </span> 
        }
        else {
            price = <span>
                <NumberFormat value={this.props.product.promoPrice} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
            </span>
        }

        return (
            <NavLink to={`/product/${this.props.product.id}-${this.props.product.name.replace(/\s+/g, '-').toLowerCase()}`} style={{ textDecoration: 'none' }}>
                <div className="product-card-wrapper" title={this.props.product.name}>
                    <div className="product-card-inner">
                        {display_pict}
                        <div className="product-title-card">
                            <div className="title-product-card">
                                {/* <div className="loading-background" style={{ width: '300px', height: '20px', marginBottom: '8px' }}></div> */}
                                <span>
                                   {this.props.product.name}
                                </span>
                            </div>
                            <div className="product-price-card">
                                {/* <div className="loading-background" style={{ width: '85px', height: '20px', marginBottom: '8px' }}></div> */}
                                {price}



                            </div>
                        </div>
                    </div>
                </div>
            </NavLink>
        );
    }
}

export default ProductCard;