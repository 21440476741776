import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import { FindTransactionId } from "../../api/index";
import Countdown from '../../components/Countdown/Countdown';
import Moment from 'react-moment';
import Popup from '../../components/Popup/Popup';

import { ListStatusOrder, TrackingShip } from '../../api/index';

import Steps, { Step } from 'rc-steps';
// import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';
import TrackingShipping from '../../components/TrackingShipping/TrackingShipping';
import { Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';

class ReadEbook extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        const transactionId = this.props.match.params.idtransaction;
        console.log(transactionId)
        const content = {
            transactionId: transactionId
        }
        FindTransactionId(content).then(res => {
            console.log(res[0])
            const url_product = res[0].OrderProducts.find(item => item.productId == this.props.match.params.idproduct )
            console.log(url_product)
            this.setState({
                data: {
                },
                listProduct: res[0].OrderProducts,
                date: res[0].expireDate,
                isFetching: false,
                urlproduct: url_product
            })
        }).catch(err => {
            console.log(err)
        })
    }

    
    
        render() {
             console.log(this.state?.url_product?.Product)

            return (
                <iframe allowFullScreen="allowFullScreen" scrolling="no" className="fp-iframe" 
                src={this.state?.urlproduct?.Product.url_ebook} 
                style={{border: "1px solid lightgray", width: "100%" , height: "600px"}}
                 
                >

            </iframe>

            )
            
    }

}

export default withRouter(ReadEbook);

